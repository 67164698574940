import { createSlice } from '@reduxjs/toolkit';

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    classID: '1',
    studentID: '1',
    course: 'scratch',
    levels: [
      'Blocks Introduction',
      'Coordinate System & Movements',
      'Variables',
      'Conditions',
      'Loops',
      'Operators',
      'Looks & Sounds',
      'Sprites & Stage',
      'Functions',
      'Events',
      'Sensing',
      'Pen',
      'Project 1',
      'Project 2',
      'Project 3',
    ],
    assignments: [],
  },
  reducers: {
    setClassID: (state, action) => {
      state.classID = action.payload;
    },
    setStudentID: (state, action) => {
      state.studentID = action.payload;
    },
    setCourse: (state, action) => {
      state.course = action.payload;
    },
  }
})

export const { setClassID, setStudentID, setCourse } = assignmentsSlice.actions

export default assignmentsSlice.reducer