import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import MenuAppBar from './components/MenuAppBar';
import Drawer from './components/Drawer';
import DrawerHeader from './components/DrawerHeader';
import StudentsList from './components/StudentsList';
import Attendance from './components/Attendance';
import AssignmentsResult from './components/AssignmentsResult';
import ClassList from './components/ClassList';
import About from './components/About';
import ViewQuizResult from './components/ViewQuizResult';
import TakeAttendance from './components/TakeAttendance';
import AddQuiz from './components/AddQuiz';
import StartQuiz from './components/StartQuiz';
import ListQuizzes from './components/ListQuizzes';
import Settings from './components/Settings';
import CodeAssignment from './components/CodeAssignment';
import BlocklyAssignment from './components/BlocklyAssignment';
import CustomGameLevel from './components/CustomGameLevel';
import LoginDialog from './components/LoginDialog';
import ViewBlocklyAssignment from './components/ViewBlocklyAssignment';
import { Typography } from '@mui/material';

export default function Dashboard() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState('Classes');
  
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const handleCloseLoginDialog = () => {
    setOpenLoginDialog(false);
  };

  const handleOpenUserLoginDialog = () => {
    setOpenLoginDialog(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOnOpenSettings = () => {
    setCurrentPage('Settings');
  };

  const menuItems = [
    {
      label: "Classes",
      icon: <SchoolOutlinedIcon />,
    },
    {
      label: "Students",
      icon: <PeopleAltOutlinedIcon />,
    },
    {
      label: "DIVIDER",
      icon: null,
    },
    {
      label: "Take Attendance",
      icon: <PanToolOutlinedIcon />,
    },
    {
      label: "View Attendance",
      icon: <CalendarMonthOutlinedIcon />,
    },
    {
      label: "DIVIDER",
      icon: null,
    },
    {
      label: "CodeSkool Assignment",
      icon: <DesignServicesOutlinedIcon />,
    },
    {
      label: "View Assignments",
      icon: <AssignmentTurnedInOutlinedIcon />,
    },
    {
      label: "DIVIDER",
      icon: null,
    },
    {
      label: "Code Assignment",
      icon: <CodeOutlinedIcon />,
    },
    {
      label: "Game Progress",
      icon: <InsightsOutlinedIcon />,
    },
    {
      label: "Custom Game Level",
      icon: <SportsEsportsOutlinedIcon />,
    },
    {
      label: "DIVIDER",
      icon: null,
    },
    {
      label: "Add Quiz",
      icon: <AddBoxOutlinedIcon />,
    },
    {
      label: "List Quizzes",
      icon: <QuestionAnswerOutlinedIcon />,
    },
    {
      label: "Start Quiz",
      icon: <PendingActionsOutlinedIcon />,
    },
    {
      label: "View Result",
      icon: <AssessmentOutlinedIcon />,
    },
    {
      label: "DIVIDER",
      icon: null,
    },
    {
      label: "About",
      icon: <InfoOutlinedIcon />,
    }
  ]

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MenuAppBar open={open} handleDrawerOpen={handleDrawerOpen} onUserSettings={handleOnOpenSettings} onUserLogin={handleOpenUserLoginDialog} />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "-webkit-fill-available", pl:2}}>
            <DashboardOutlinedIcon sx={{mr:2}} color={theme.palette.light}/>
            <span>Dashboard</span>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {
            menuItems.map((mItem, index) => (
              <ListItem key={`${mItem.label}${index}`} disablePadding sx={{ display: 'block' }}>
                {
                  mItem.label === "DIVIDER" ? (
                    <Divider />
                  ) : (
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={() => setCurrentPage(mItem.label)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {mItem.icon}
                      </ListItemIcon>
                      <ListItemText primary={mItem.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  )}
              </ListItem>
            ))
          }
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minHeight: '96vh'}}>
          <DrawerHeader />
          <Box sx={{flexGrow: 1, mb:2}}>
            {currentPage === "Classes" && <ClassList />}
            {currentPage === 'Students' && <StudentsList />}
            {currentPage === 'Take Attendance' && <TakeAttendance />}
            {currentPage === 'View Attendance' && <Attendance />}
            {currentPage === 'Game Progress' && <AssignmentsResult />}
            {currentPage === 'CodeSkool Assignment' && <BlocklyAssignment />}
            {currentPage === 'View Assignments' && <ViewBlocklyAssignment />}
            {currentPage === 'Code Assignment' && <CodeAssignment />}
            {currentPage === 'Custom Game Level' && <CustomGameLevel />}
            {currentPage === 'Add Quiz' && <AddQuiz />}
            {currentPage === 'List Quizzes' && <ListQuizzes />}
            {currentPage === 'Start Quiz' && <StartQuiz />}
            {currentPage === 'View Result' && <ViewQuizResult />}
            {currentPage === 'About' && <About />}
            {currentPage === 'Settings' && <Settings />}
          </Box>
          <Box>
            <Typography variant="body2">Copyright &copy; 2022-{
              new Date().getFullYear()
            } Hacktronics India. All rights reserved.</Typography>
          </Box>
        </Box>
      </Box>
      <LoginDialog open={openLoginDialog} onClose={handleCloseLoginDialog} />
    </Box>
  );
}
