import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ViewSolution from '../ViewSolution';
import { setClassID, setStudentID, setCourse } from '../../reducers/assignments/assignmentsSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 2,
    maxWidth: '1px',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 2,
    textAlign: 'center',
  },
}));

const StyledLeftAlignTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 2,
    maxWidth: '1px',
    textAlign: 'left',
    paddingLeft: 10,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 2,
    textAlign: 'left',
    paddingLeft: 10,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const assignmentsPerLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export default function AssignmentsResult() {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classes.classes);
  const students = useSelector((state) => state.students.students);
  const classID = useSelector((state) => state.assignments.classID);
  const studentID = useSelector((state) => state.assignments.studentID);
  const course = useSelector((state) => state.assignments.course);
  const levels = useSelector((state) => state.assignments.levels);

  const handleClassChange = (event) => {
    dispatch(setClassID(event.target.value));
  };

  const handleStudentChange = (event) => {
    dispatch(setStudentID(event.target.value));
  };

  const handleCourseChange = (event) => {
    dispatch(setCourse(event.target.value));
  };

  const [showSolution, setShowSolution] = React.useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={classID}
              label="Class"
              onChange={handleClassChange}
            >
              {
                classes.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="student-select-label">Student</InputLabel>
            <Select
              labelId="student-select-label"
              id="student-select"
              value={studentID}
              label="Student"
              onChange={handleStudentChange}
            >
              {
                students.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.firstName} {item.lastName}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="course-select-label">Course</InputLabel>
            <Select
              labelId="course-select-label"
              id="course-select"
              value={course}
              label="Course"
              onChange={handleCourseChange}
            >
              <MenuItem value={'scratch'}>Scratch</MenuItem>
              <MenuItem value={'python'}>Python</MenuItem>
              <MenuItem value={'farm'}>Farm Game</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
        </Box>
        <Button variant="contained" color="primary" onClick={e => {}} startIcon={<FileDownloadOutlinedIcon />}>
          Download
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size={'medium'}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Assignment</StyledTableCell>
              {
                assignmentsPerLevel.map((id) => {
                  return <StyledTableCell align="right" key={id}>{id}</StyledTableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {levels.map((level) => (
              <StyledTableRow key={level}>
                <StyledLeftAlignTableCell component="th" scope="row">
                  {level}
                </StyledLeftAlignTableCell>
                {
                  assignmentsPerLevel.map((id) => {
                    return (
                      <StyledTableCell align="right" key={id}>
                        <IconButton aria-label="correct" color="success" onClick={e => {setShowSolution(true)}}>
                          <CheckOutlinedIcon/>
                        </IconButton>
                      </StyledTableCell>
                    )
                  })
                }
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ViewSolution open={showSolution} onClose={() => setShowSolution(false)} />
    </Box>
  );
}
