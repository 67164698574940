import { createSlice } from '@reduxjs/toolkit';

export const quizResultSlice = createSlice({
  name: 'quizResult',
  initialState: {
    results: [],
  },
  reducers: {
    addQuizResult: (state, action) => {
      state.results = [...state.results, action.payload];
    },
    deleteQuizResult: (state, action) => {
      state.results = state.results.filter(res => res.id !== action.payload);
    },
  }
})

export const { addQuizResult, deleteQuizResult } = quizResultSlice.actions

export default quizResultSlice.reducer