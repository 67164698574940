import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import Chip from '@mui/material/Chip';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useSelector, useDispatch } from 'react-redux';

const StartQuiz = (props) => {
  const dispatch = useDispatch();
  const quizzes = useSelector((state) => state.quiz.quizzes);
  const classes = useSelector((state) => state.classes.classes);
  const [selectedClass, setSelectedClass] = React.useState(classes.length > 0 ? classes[0].id : '');
  const [selectedQuiz, setSelectedQuiz] = React.useState(quizzes.length > 0 ? quizzes[0].id : '');

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleQuizChange = (event) => {
    setSelectedQuiz(event.target.value);
  };

  const [totalTime, setTotalTime] = React.useState(0);
  const [currentQuestionTime, setCurrentQuestionTime] = React.useState(0);

  const [isStarted, setIsStarted] = React.useState(false);

  useEffect(() => {
    if (isStarted) {
      const timer = setTimeout(() => {
        setCurrentQuestionTime(currentQuestionTime + 1);
        setTotalTime(totalTime + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [currentQuestionTime, isStarted, totalTime]);

  const secondsToHMS = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h <= 9 ? `0${h}` : h;
    var mDisplay = m <= 9 ? `0${m}` : m;
    var sDisplay = s <= 9 ? `0${s}` : s;
    return hDisplay + ":" + mDisplay + ":" + sDisplay;
  }

  const secondsToMS = (d) => {
    d = Number(d);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var mDisplay = m <= 9 ? `0${m}` : m;
    var sDisplay = s <= 9 ? `0${s}` : s;
    return mDisplay + ":" + sDisplay;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {
        isStarted ? (
          <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
            <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Question 1 of 10
              </Typography>
            </Box>
            <Chip label={
              <Box sx={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                <AccessTimeOutlinedIcon sx={{mr:1}}/>
                <Typography>
                  {secondsToHMS(totalTime)}
                </Typography>
              </Box>
            } variant="outlined" />
          </Box>
        ) : (
          <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
            <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
              <FormControl sx={{width: "20rem"}}>
                <InputLabel id="class-select-label">Class</InputLabel>
                <Select
                  labelId="class-select-label"
                  id="class-select"
                  value={selectedClass}
                  label="Class"
                  onChange={handleClassChange}
                >
                  {
                    classes.map((item) => (
                      <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <Box sx={{mx: 1}}></Box>
              <FormControl sx={{width: "20rem"}}>
                <InputLabel id="quiz-select-label">Quiz</InputLabel>
                <Select
                  labelId="quiz-select-label"
                  id="quiz-select"
                  value={selectedQuiz}
                  label="Quiz"
                  onChange={handleQuizChange}
                >
                  {
                    quizzes.map((item) => (
                      <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <Box sx={{mx: 1}}></Box>
            </Box>
            <Button variant="contained" color="primary" startIcon={<PlayArrowOutlinedIcon />} onClick={() => setIsStarted(true)}>
              Start
            </Button>
          </Box>
        )
      }
      {
        isStarted && (
          <>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Word of the Day
                </Typography>
                <Typography variant="h5" component="div">
                  benevlent
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  adjective
                </Typography>
                <Typography variant="body2">
                  well meaning and kindly.
                  <br />
                  {'"a benevolent smile"'}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Learn More</Button>
              </CardActions>
            </Card>
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", mb: 2, mt:2}}>
              <Paper elevation={3} sx={{mr:1, width: '-webkit-fill-available'}}>
                <Box sx={{p: 2, display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                  <Typography variant="h5" component="div" sx={{mr:2}}>
                    A
                  </Typography>
                  <Typography variant="body2">
                    What is the meaning of benevolent?
                  </Typography>
                </Box>
              </Paper>
              <Paper elevation={3} sx={{ml:1, width: '-webkit-fill-available'}}>
                <Box sx={{p: 2, display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                  <Typography variant="h5" component="div" sx={{mr:2}}>
                    B
                  </Typography>
                  <Typography variant="body2">
                    What is the meaning of benevolent?
                  </Typography>
                </Box>
              </Paper>
            </Box>
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", mb: 2, mt:2}}>
              <Paper elevation={3} sx={{mr:1, width: '-webkit-fill-available'}}>
                <Box sx={{p: 2, display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                  <Typography variant="h5" component="div" sx={{mr:2}}>
                    C
                  </Typography>
                  <Typography variant="body2">
                    What is the meaning of benevolent?
                  </Typography>
                </Box>
              </Paper>
              <Paper elevation={3} sx={{ml:1, width: '-webkit-fill-available'}}>
                <Box sx={{p: 2, display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                  <Typography variant="h5" component="div" sx={{mr:2}}>
                    D
                  </Typography>
                  <Typography variant="body2">
                    What is the meaning of benevolent?
                  </Typography>
                </Box>
              </Paper>
            </Box>
            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2, mt:5, pt:5}}>
              <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                {
                  currentQuestionTime % 2 === 0 ? <HourglassBottomOutlinedIcon sx={{mr:1}}/> : <HourglassTopOutlinedIcon sx={{mr:1}}/>
                }
                <Typography variant="subtitle1" component="div" sx={{display:"inline"}}>
                  {secondsToMS(currentQuestionTime)}
                </Typography>
              </Box>
              <Box>
                <Button variant="outlined" startIcon={<ArrowLeftOutlinedIcon />} sx={{mr:0.5}}>
                  Previous
                </Button>
                <Button variant="outlined" endIcon={<ArrowRightOutlinedIcon />} sx={{ml:0.5}}>
                  Next
                </Button>
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  )
};

StartQuiz.propTypes = {}

export default StartQuiz;
