import { createSlice } from '@reduxjs/toolkit';

function createData(name, teacherFistName, teacherLastName, email, grade, section, id) {
  return {
    name,
    teacherFistName,
    teacherLastName,
    email,
    grade,
    section,
    id,
  };
}

const rows = [
  createData('Grade 3(A)', 'Aarav', 'Mittal', 'aarav@skool.cc', 3, 'A', '1'),
  createData('Grade 3(B)', 'Rohit', 'Gupta', 'rohit@skool.cc', 3, 'B', '2'),
  createData('Grade 3(C)', 'Shyama', 'Mukherje', 'shyama@skool.cc', 3, 'C', '3'),
  createData('Grade 2(A)', 'Ritesh', 'Mathur', 'ritesh@skool.cc', 2, 'A', '4'),
  createData('Grade 2(B)', 'Vaihav', 'Shah', 'vaihav@skool.cc', 2, 'B', '5'),
  createData('Grade 2(C)', 'Malini', 'Ayer', 'malini@skool.cc', 2, 'C', '6'),
  createData('Grade 4(A)', 'Shalu', 'Kumari', 'shalu@skool.cc', 4, 'A', '7'),
  createData('Grade 4(B)', 'Shubam', 'Kumar', 'shubam@skool.cc', 4, 'B', '8'),
  createData('Grade 4(C)', 'Asif', 'Mohammad', 'asif@skool.cc', 4, 'C', '9'),
  createData('Grade 5(A)', 'Ritika', 'Munoth', 'ritika@skool.cc', 5, 'A', '10'),
  createData('Grade 5(B)', 'Payal', 'Sareen', 'payal@skool.cc', 5, 'B', '11'),
  createData('Grade 5(C)', 'Anshul', 'Gupta', 'anshul@skool.cc', 5, 'C', '12'),
  createData('Grade 5(D)', 'Venkat', 'Babu', 'venkat@skool.cc', 5, 'D', '13'),
];

export const classesSlice = createSlice({
  name: 'classes',
  initialState: {
    classes: rows,
  },
  reducers: {
    addClass: (state, action) => {
      state.classes = [...state.classes, action.payload];
    },
    removeClass: (state, action) => {
      state.classes = state.classes.filter((item) => item.id !== action.payload);
    },
    editClass: (state, action) => {
      state.classes = state.classes.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
  }
})

export const { addClass, removeClass, editClass } = classesSlice.actions

export default classesSlice.reducer