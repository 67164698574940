import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { saveUserSettings } from '../../reducers/user/userSlice';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const Settings = (props) => {
  const dispatch = useDispatch();
  const userFirstName = useSelector(state => state.user.firstName);
  const userLastName = useSelector(state => state.user.lastName);
  const userAvatar = useSelector(state => state.user.avatar);

  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [avatar, setAvatar] = useState(userAvatar);

  const onSaveUserSettings = (e) => {
    e.preventDefault();
    dispatch(saveUserSettings({firstName, lastName, avatar}));
  };

  return (
    <Box>
      <Typography variant="h6">Settings</Typography>
      <Box sx={{display: "flex", flexDirection: "row", mt: 5}}>
        <TextField id="fname" label="First Name" variant="outlined"
          value={firstName} onChange={(e) => setFirstName(e.target.value)}
          sx={{width: '-webkit-fill-available', mr: 1}}
        />
        <TextField id="lname" label="Last Name" variant="outlined"
          value={lastName} onChange={(e) => setLastName(e.target.value)}
          sx={{width: '-webkit-fill-available', ml: 1}}
        />
      </Box>
      <Box sx={{mt:2}}>
        <TextField id="avatar" label="Avatar" variant="outlined" value={avatar} onChange={(e) => setAvatar(e.target.value)} sx={{width: '-webkit-fill-available'}} />
      </Box>
      <Box sx={{mt:5, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
        <Button variant="contained" onClick={onSaveUserSettings} startIcon={<SaveOutlinedIcon />}>Save</Button>
      </Box>
    </Box>
  )
};

Settings.propTypes = {}

export default Settings;