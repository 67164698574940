import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';

export default function AddQuestion({open, handleClose, editID, onQuestionAdd, onQuestionEdit}) {
  const dispatch = useDispatch();
  const quizQuestions = useSelector((state) => state.addQuiz.questions);
  const [quizQuestion, setQuizQuestion] = React.useState('');
  const [optionA, setOptionA] = React.useState('');
  const [optionB, setOptionB] = React.useState('');
  const [optionC, setOptionC] = React.useState('');
  const [optionD, setOptionD] = React.useState('');
  const [timeLimit, setTimeLimit] = React.useState(dayjs('2022-12-02'));
  const [marks, setMarks] = React.useState(2);
  const [correctAnswer, setCorrectAnswer] = React.useState('A');
  const [id, setID] = React.useState(uuidv4());

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'align': []}],
      ['link', 'image'],
      ['clean']
    ],
  };

  React.useEffect(() => {
    if (editID) {
      const editQuestion = quizQuestions.find((item) => item.id === editID);
      setQuizQuestion(editQuestion.question);
      setOptionA(editQuestion.optionA);
      setOptionB(editQuestion.optionB);
      setOptionC(editQuestion.optionC);
      setOptionD(editQuestion.optionD);
      const t = editQuestion.timeLimit.split(":");
      if(t.length === 2) {
        setTimeLimit(dayjs(`2022-12-02 00:${editQuestion.timeLimit}`));
      } else {
        setTimeLimit(dayjs('2022-12-02'));
      }
      setMarks(editQuestion.marks);
      setCorrectAnswer(editQuestion.answer);
      setID(editQuestion.id);
    } else {
      setQuizQuestion('');
      setOptionA('');
      setOptionB('');
      setOptionC('');
      setOptionD('');
      setTimeLimit(dayjs('2022-12-02'));
      setMarks('');
      setCorrectAnswer('');
      setID(uuidv4());
    }
  }, [quizQuestions, editID]);

  const handleOnQuestionAdd = (e) => {
    const questionData = {
      id,
      question: quizQuestion,
      optionA,
      optionB,
      optionC,
      optionD,
      answer: correctAnswer,
      timeLimit: timeLimit.format('mm:ss'),
      marks
    }
    if(editID) {
      onQuestionEdit(questionData);
    } else {
      onQuestionAdd(questionData);
    }
    handleClose(e);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{editID ? "Edit" : "Add"} Question</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the question details
          </DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
            <ReactQuill theme="snow" value={quizQuestion} onChange={setQuizQuestion} modules={modules} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 0 }}>
            <TextField
              autoFocus
              margin="dense"
              id="optionA"
              label="Option A"
              type="text"
              fullWidth
              value={optionA}
              onChange={(e) => setOptionA(e.target.value)}
              variant="outlined"
              placeholder='China'
              sx={{mt:2, mr:1}}
            />
            <TextField
              autoFocus
              margin="dense"
              id="optionB"
              label="Option B"
              type="text"
              fullWidth
              value={optionB}
              onChange={(e) => setOptionB(e.target.value)}
              variant="outlined"
              placeholder='USA'
              sx={{mt:2, ml:1}}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 0 }}>
            <TextField
              autoFocus
              margin="dense"
              id="optionC"
              label="Option C"
              type="text"
              fullWidth
              value={optionC}
              onChange={(e) => setOptionC(e.target.value)}
              variant="outlined"
              placeholder='India'
              sx={{mt:2, mr:1}}
            />
            <TextField
              autoFocus
              margin="dense"
              id="optionD"
              label="Option D"
              type="text"
              fullWidth
              value={optionD}
              onChange={(e) => setOptionD(e.target.value)}
              variant="outlined"
              placeholder='UK'
              sx={{mt:2, ml:1}}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
            <FormControl  sx={{mt: 1, mr:2, width: '-webkit-fill-available'}}>
              <InputLabel id="correct-answer-select-label">Correct Answer</InputLabel>
              <Select
                labelId="correct-answer-select-label"
                id="correct-answer-select"
                value={correctAnswer}
                label="Correct Answer"
                onChange={e => setCorrectAnswer(e.target.value)}
              >
                <MenuItem value={'A'}>A</MenuItem>
                <MenuItem value={'B'}>B</MenuItem>
                <MenuItem value={'C'}>C</MenuItem>
                <MenuItem value={'D'}>D</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampmInClock
                views={['minutes', 'seconds']}
                inputFormat="mm:ss"
                mask="__:__"
                label="Time Limit"
                value={timeLimit}
                onChange={(newValue) => {
                  setTimeLimit(newValue);
                }}
                renderInput={(params) => <TextField {...params}  sx={{mt:1, width: '-webkit-fill-available'}}/>}
              />
            </LocalizationProvider>
            <TextField
              autoFocus
              margin="dense"
              id="marks"
              label="Marks"
              type="number"
              fullWidth
              value={marks}
              onChange={(e) => setMarks(e.target.value)}
              variant="outlined"
              placeholder='2'
              sx={{ml:2, width: '-webkit-fill-available'}}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOnQuestionAdd}>{editID ? "Save" : "Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
