import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { addClass, editClass } from '../../reducers/classes/classesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export default function AddClass({open, handleClose, editID}) {
  const dispatch = useDispatch();
  const allClasses = useSelector((state) => state.classes.classes);
  const [name, setName] = React.useState('');
  const [teacherFistName, setTeacherFirstName] = React.useState('');
  const [teacherLastName, setTeacherLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [grade, setGrade] = React.useState(5);
  const [section, setSection] = React.useState('A');
  const [id, setID] = React.useState(uuidv4());

  React.useEffect(() => {
    if (editID) {
      const editClass = allClasses.find((item) => item.id === editID);
      setName(editClass.name);
      setTeacherFirstName(editClass.teacherFistName);
      setTeacherLastName(editClass.teacherLastName);
      setEmail(editClass.email);
      setGrade(editClass.grade);
      setSection(editClass.section);
      setID(editClass.id);
    } else {
      setName('');
      setTeacherFirstName('');
      setTeacherLastName('');
      setEmail('');
      setGrade(5);
      setSection('A');
      setID(uuidv4());
    }
  }, [allClasses, editID]);

  const handleSectionChange = (event) => {
    setSection(event.target.value);
  };

  const handleAddClass = (e) => {
    e.preventDefault();
    if(editID) {
      dispatch(editClass({
        name,
        teacherFistName,
        teacherLastName,
        email,
        grade,
        section,
        id,
      }));
    } else {
      dispatch(addClass({
        name,
        teacherFistName,
        teacherLastName,
        email,
        grade,
        section,
        id,
      }));
    }
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editID ? "Edit" : "Add"} Class</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details of the class:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            placeholder='Grade 5(A)'
            sx={{mt:2}}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              id="teacherFistName"
              label="Teacher First Name"
              type="text"
              fullWidth
              value={teacherFistName}
              onChange={(e) => setTeacherFirstName(e.target.value)}
              variant="outlined"
              placeholder='Radha'
              sx={{mt:2, mr:1}}
            />
            <TextField
              autoFocus
              margin="dense"
              id="teacherLastName"
              label="Teacher Last Name"
              type="text"
              fullWidth
              value={teacherLastName}
              onChange={(e) => setTeacherLastName(e.target.value)}
              variant="outlined"
              placeholder='krishnan'
              sx={{mt:2, ml:1}}
            />
          </Box>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            placeholder='radhakrishnan@skool.cc'
            sx={{mt:2}}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              id="grade"
              label="Grade"
              type="number"
              fullWidth
              variant="outlined"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              sx={{mr:1}}
              placeholder={'5'}
            />
            <FormControl fullWidth sx={{ml:1, mt:0.5}}>
              <InputLabel id="section-select-label">Section</InputLabel>
              <Select
                labelId="section-select-label"
                id="section-select"
                value={section}
                label="Section"
                onChange={handleSectionChange}
              >
                <MenuItem value={'A'}>A</MenuItem>
                <MenuItem value={'B'}>B</MenuItem>
                <MenuItem value={'C'}>C</MenuItem>
                <MenuItem value={'D'}>D</MenuItem>
                <MenuItem value={'E'}>E</MenuItem>
                <MenuItem value={'F'}>F</MenuItem>
                <MenuItem value={'G'}>G</MenuItem>
                <MenuItem value={'H'}>H</MenuItem>
                <MenuItem value={'I'}>I</MenuItem>
                <MenuItem value={'J'}>J</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddClass}>{editID ? "Save" : "Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
