import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const About = (props) => {
  return (
    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", width:"-webkit-fill-available", mt:2}}>
      <Typography variant="h4" component="div" sx={{mb:2}}>Skool Dashboard</Typography>
      <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", width:"-webkit-fill-available"}}>
        <Typography variant="subtitle1" component="div" sx={{mr:1}}>Version: </Typography>
        <Typography variant="subtitle2" component="div">0.0.0.1 (Alpha)</Typography>
      </Box>
    </Box>
  )
};


export default About;
