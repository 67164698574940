import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BlocklyAssignment = (props) => {
  const user = useSelector(state => state.user.user);
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState('');
  const [projectID, setProjectID] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [marks, setMarks] = useState(0);
  const [question, setQuestion] = useState('');
  const [hint, setHint] = useState('');
  const [assingmentLink, setAssingmentLink] = useState('');

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'align': []}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const onCopyAssignmentLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(assingmentLink);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddAssignment = (e) => {
    e.preventDefault();
    console.log(user);
    setActiveTab(0);
    setName('');
    setProjectID('');
    setWidth(0);
    setHeight(0);
    setMarks(0);
    setQuestion('');
    setHint('');
  };

  return (
    <Box>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <Typography variant="h6">Create CodeSkool Assignment</Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={handleAddAssignment} startIcon={<SaveOutlinedIcon />}>
          Save
        </Button>
      </Box>
      <Collapse in={assingmentLink.length > 0}>
        <Alert severity="info" sx={{ml:1, mt:2}} action={
            <Button color="inherit" size="small" onClick={onCopyAssignmentLink}>
              COPY
            </Button>
          }>
            Generated assignment link: <Link href={assingmentLink} underline="none">{assingmentLink}</Link>
        </Alert>
      </Collapse>
      <FormControl fullWidth sx={{mx:1, mt:2}}>
        <TextField id="assignment-name" label="Assignment Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
      </FormControl>
      <FormControl fullWidth sx={{mx:1, mt:2}}>
        <TextField id="project-id" label="Project ID" variant="outlined" value={projectID} onChange={(e) => setProjectID(e.target.value)} />
      </FormControl>
      <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"center", mt: 2}}>
        <FormControl fullWidth sx={{mx:1}}>
          <TextField id="assignment-marks" type="number" label="Marks" variant="outlined" value={marks} onChange={(e) => setMarks(e.target.value)} />
        </FormControl>
        <FormControl fullWidth sx={{mx:1, mt:0}}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox />}
              label="Cheer on submit"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"center", mt: 2}}>
        <FormControl fullWidth sx={{mx:1}}>
          <TextField id="assignment-width" type="number" label="Width" variant="outlined" value={width} onChange={(e) => setWidth(e.target.value)} />
        </FormControl>
        <FormControl fullWidth sx={{mx:1}}>
          <TextField id="assignment-height" type="number" label="Height" variant="outlined" value={height} onChange={(e) => setHeight(e.target.value)} />
        </FormControl>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Question" {...a11yProps(0)} />
            <Tab label="Hint" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Box sx={{my:0}}>
            <ReactQuill theme="snow" value={question} onChange={setQuestion} modules={modules} />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box sx={{my:0}}>
            <ReactQuill theme="snow" value={hint} onChange={setHint} modules={modules} />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  )
}

BlocklyAssignment.propTypes = {}

export default BlocklyAssignment;
