import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'submittedOn',
    headerName: 'Submission Date',
    width: 150,
    editable: true,
  },
  {
    field: 'marks',
    headerName: 'Marks',
    width: 150,
    editable: true,
  },
  {
    field: 'percentage',
    headerName: 'Percentage',
    description: 'This column has a value getter and is not sortable.',
    sortable: true,
    width: 260,
    renderCell: (params) => {
      return (
        <BorderLinearProgress variant="determinate" value={params.row.percentage} sx={{width: '-webkit-fill-available'}} />
      )
    },
  },
  {
    field: 'grade',
    headerName: 'Grade',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35, percentage: 78 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42, percentage: 97 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45, percentage: 58 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16, percentage: 63 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, percentage: 37 },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150, percentage: 82 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44, percentage: 94 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36, percentage: 32 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65, percentage: 65 },
];

const ViewQuizResult = (props) => {
  const dispatch = useDispatch();
  const quizzes = useSelector((state) => state.quiz.quizzes);
  const classes = useSelector((state) => state.classes.classes);
  const [selectedClass, setSelectedClass] = React.useState(classes.length > 0 ? classes[0].id : '');
  const [selectedQuiz, setSelectedQuiz] = React.useState(quizzes.length > 0 ? quizzes[0].id : '');

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleQuizChange = (event) => {
    setSelectedQuiz(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={selectedClass}
              label="Class"
              onChange={handleClassChange}
            >
              {
                classes.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="quiz-select-label">Quiz</InputLabel>
            <Select
              labelId="quiz-select-label"
              id="quiz-select"
              value={selectedQuiz}
              label="Quiz"
              onChange={handleQuizChange}
            >
              {
                quizzes.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
        </Box>
        <Button variant="contained" color="primary" onClick={e => {}} startIcon={<ForwardToInboxOutlinedIcon />} sx={{mr:1}}>
          Email
        </Button>
        <Button variant="contained" color="primary" onClick={e => {}} startIcon={<FileDownloadOutlinedIcon />}>
          Reports
        </Button>
      </Box>
      <Box sx={{ height: 371, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
  )
};

export default ViewQuizResult;
