import { configureStore } from '@reduxjs/toolkit';
import classesReducer from './reducers/classes/classesSlice';
import studentsReducer from './reducers/students/studentsSlice';
import attendanceReducer from './reducers/attendance/attendanceSlice';
import userReducer from './reducers/user/userSlice';
import assignmentsReducer from './reducers/assignments/assignmentsSlice';
import quizReducer from './reducers/quiz/quizSlice';
import addQuizReducer from './reducers/addQuiz/addQuizSlice';
import quizResultReducer from './reducers/quizResult/quizResultSlice';
import blocklyAssignmentReducer from './reducers/blocklyAssignment/blocklyAssignmentSlice';

export default configureStore({
  reducer: {
    classes: classesReducer,
    students: studentsReducer,
    attendance: attendanceReducer,
    user: userReducer,
    assignments: assignmentsReducer,
    quiz: quizReducer,
    addQuiz: addQuizReducer,
    quizResult: quizResultReducer,
    blocklyAssignment: blocklyAssignmentReducer,
  }
})