import React, {useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useSelector, useDispatch } from 'react-redux';
import {db, auth} from '../../firebase/config';
import {doc, getDoc} from 'firebase/firestore';
import {signOut, onAuthStateChanged} from 'firebase/auth';
import {setUser} from '../../reducers/user/userSlice';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MenuAppBar({open, handleDrawerOpen, onUserSettings, onUserLogin}) {
  const dispatch = useDispatch();
  const userFirstName = useSelector(state => state.user.firstName);
  const userLastName = useSelector(state => state.user.lastName);
  const userAvatar = useSelector(state => state.user.avatar);
  const user = useSelector(state => state.user.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickSettingsMenu = (e) => {
    e.preventDefault();
    handleClose();
    onUserSettings();
  };

  const handleOnClickLogoutMenu = (e) => {
    e.preventDefault();
    signOut(auth).then(() => {
      dispatch(setUser(null));
      handleClose();
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      dispatch(setUser(user.uid));      
    })
  }, [dispatch]);

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Skool
        </Typography>
        {user && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar alt={`${userFirstName} ${userLastName}`} src={userAvatar} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 0,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 25,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleOnClickSettingsMenu}>Settings</MenuItem>
              <MenuItem onClick={handleOnClickLogoutMenu}>Logout</MenuItem>
            </Menu>
          </div>
        )}
        {!user && (
          <Button variant="outlined" onClick={onUserLogin} sx={{
            ':hover': {
              bgcolor: 'primary.main', // theme.palette.primary.main
              color: 'white',
              borderColor: 'white',
            },
          }}>
            <Typography variant="span" sx={{ color: 'white' }}>
              Login
            </Typography>
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
