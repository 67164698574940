import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    firstName: 'GIIS',
    lastName: 'Whitefield',
    avatar: 'https://cdn.international-schools-database.com/system/premium_profiles/logos/000/000/118/medium/Logo.jpeg',
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    saveUserSettings: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.avatar = action.payload.avatar;
    },
  }
})

export const { setUser, setFirstName, setLastName, setAvatar, saveUserSettings } = userSlice.actions

export default userSlice.reducer