import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { deleteQuiz } from '../../reducers/quiz/quizSlice';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
    editable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    editable: false,
  },
  {
    field: 'difficulty',
    headerName: 'Difficulty',
    width: 110,
    editable: false,
  },
  {
    field: 'totalQuestions',
    headerName: 'Questions',
    type: 'number',
    width: 110,
    editable: false,
    valueGetter: (params) => params.row.questions.length,
  },
  {
    field: 'totalMarks',
    headerName: 'Marks',
    type: 'number',
    sortable: true,
    width: 110,
    valueGetter: (params) => {    
      return params.row.questions.reduce((total, question) => total + question.marks, 0);
    }
  },
  {
    field: 'totalTime',
    headerName: 'Time',
    width: 160,
    valueGetter: (params) => {
      const time = params.row.questions.reduce((total, question) => {
        const t = question.timeLimit.split(":");
        return total + (parseInt(t[0]) * 60) + parseInt(t[1]);
      }, 0);
      const hour = Math.floor(time / 3600);
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${hour}h ${minutes}m ${seconds}s`;
    }
  },
  // {
  //   field: 'percentage',
  //   headerName: 'Percentage',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: true,
  //   width: 260,
  //   renderCell: (params) => {
  //     return (
  //       <BorderLinearProgress variant="determinate" value={params.row.percentage} sx={{width: '-webkit-fill-available'}} />
  //     )
  //   },
  // },
];

const ListQuizzes = (props) => {
  const dispatch = useDispatch();
  const quizzes = useSelector((state) => state.quiz.quizzes);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleDeleteQuiz = (e) => {
    e.preventDefault();
    selectedRows.forEach((row) => {
      dispatch(deleteQuiz(row));
    });
    setSelectedRows([]);
  };

  const handleShareQuiz = (e) => {
    e.preventDefault();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
        </Box>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center"}}>
          <Button variant="contained" color="primary" onClick={handleShareQuiz} startIcon={<ShareOutlinedIcon />} sx={{mr:1}}>
            Share
          </Button>
          <Button variant="contained" color="primary" onClick={handleDeleteQuiz} startIcon={<DeleteOutlinedIcon />}>
            Delete
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: 371, width: '100%' }}>
        <DataGrid
          rows={quizzes}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
          selectionModel={selectedRows}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
  )
};

export default ListQuizzes;
