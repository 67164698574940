import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddQuestion from '../AddQuestion';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { deleteQuizQuestions, editQuizQuestion } from '../../reducers/addQuiz/addQuizSlice';
import { htmlToText } from 'html-to-text';

const columns = [
  {
    field: 'question',
    headerName: 'Question',
    width: 360,
    editable: false,
    renderCell: (params) => {
      const q = htmlToText(params.row.question, {
        wordwrap: 130
      });
      if (q.length > 130) {
        return q.substring(0, 130) + '...';
      }
      return q;
    },
  },
  {
    field: 'optionA',
    headerName: 'Option A',
    width: 135,
    editable: false,
  },
  {
    field: 'optionB',
    headerName: 'Option B',
    width: 135,
    editable: false,
  },
  {
    field: 'optionC',
    headerName: 'Option C',
    width: 135,
    editable: false,
  },
  {
    field: 'optionD',
    headerName: 'Option D',
    width: 135,
    editable: false,
  },
  {
    field: 'answer',
    headerName: 'Answer',
    width: 80,
    editable: false,
  },
  {
    field: 'timeLimit',
    headerName: 'Time',
    description: 'This column has a value getter and is not sortable.',
    editable: false,
    width: 90,
  },
  {
    field: 'marks',
    headerName: 'Marks',
    type: 'number',
    width: 70,
    editable: true,
  }
];

const QuesitonsList = (props) => {
  const dispatch = useDispatch();
  const [openAddQues, setOpenAddQues] = React.useState(false);
  const quizQuestions = useSelector((state) => state.addQuiz.questions);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [editID, setEditID] = React.useState(null);

  const handleCloseAddQues = (e) => {
    e.preventDefault();
    setOpenAddQues(false);
  };

  const handleOnAddQuestion = (e) => {
    e.preventDefault();
    setEditID(null);
    setOpenAddQues(true);
  };

  const handleOnEditQuestion = (e) => {
    e.preventDefault();
    if(selectedRows.length > 0) {
      setEditID(selectedRows[0]);
    }
    setOpenAddQues(true);
  };

  const handleOnDeleteQuestion = (e) => {
    e.preventDefault();
    setEditID(null);
    dispatch(deleteQuizQuestions(selectedRows));
    setSelectedRows([]);
  };

  const handleOnQuestionAdd = ({id, question, optionA, optionB, optionC, optionD, answer, timeLimit, marks}) => {
    console.log(id, question, optionA, optionB, optionC, optionD, answer, timeLimit, marks);
    //dispatch(addQuizQuestion({id, question, optionA, optionB, optionC, optionD, answer, timeLimit, marks}));
  };

  const handleOnQuestionEdit = ({id, question, optionA, optionB, optionC, optionD, answer, timeLimit, marks}) => {
    dispatch(editQuizQuestion({id, question, optionA, optionB, optionC, optionD, answer, timeLimit, marks}));
  };

  return (
    <Box sx={{display:"flex", flexDirection: "column"}}>
      <Box sx={{display:"flex", justifyContent:"flex-end", alignItems:"center", mb: 2, width: '-webkit-fill-available'}}>
        <Button startIcon={<AddOutlinedIcon />} onClick={handleOnAddQuestion}>Add</Button>
        <Button startIcon={<EditOutlinedIcon />} onClick={handleOnEditQuestion}>Edit</Button>
        <Button startIcon={<DeleteOutlinedIcon />} onClick={handleOnDeleteQuestion}>Delete</Button>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: 371, width: '100%' }}>
          <DataGrid
            rows={quizQuestions}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedRows(newSelectionModel);
            }}
            selectionModel={selectedRows}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
        <AddQuestion open={openAddQues} handleClose={handleCloseAddQues} onQuestionAdd={handleOnQuestionAdd} onQuestionEdit={handleOnQuestionEdit} editID={editID}/>
      </Box>
    </Box>
  )
};

QuesitonsList.propTypes = {}

export default QuesitonsList;
