import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { addStudent, editStudent } from '../../reducers/students/studentsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export default function AddStudent({open, handleClose, editID}) {
  const dispatch = useDispatch();
  const allClasses = useSelector((state) => state.classes.classes);
  const allStudents = useSelector((state) => state.students.students);
  const [rollNo, setRollNo] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [classID, setClassID] = React.useState(allClasses.length > 0 ? allClasses[0].id : '');
  const [gender, setGender] = React.useState('M');
  const [id, setID] = React.useState(uuidv4());

  const [dob, setDOB] = React.useState(dayjs('2022-11-29'));

  const handleDOBChange = (newValue) => {
    setDOB(newValue);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  React.useEffect(() => {
    if (editID) {
      const editStudent = allStudents.find((item) => item.id === editID);
      setRollNo(editStudent.rollNo);
      setFirstName(editStudent.firstName);
      setLastName(editStudent.lastName);
      setClassID(editStudent.classID);
      setEmail(editStudent.email);
      setGender(editStudent.gender);
      setDOB(dayjs(editStudent.dob));
      setID(editStudent.id);
    } else {
      setRollNo(1);
      setFirstName('');
      setLastName('');
      setClassID(allClasses.length > 0 ? allClasses[0].id : '');
      setEmail('');
      setGender('M');
      setDOB(dayjs('2022-11-29'));
      setID(uuidv4());
    }
  }, [allClasses, allStudents, editID]);

  const handleAddStudent = (e) => {
    e.preventDefault();
    const birthday = dob.format('YYYY-MM-DD');
    if(editID) {
      dispatch(editStudent({
        rollNo,
        firstName,
        lastName,
        classID,
        email,
        dob: birthday,
        gender,
        id,
      }));
    } else {
      dispatch(addStudent({
        rollNo,
        firstName,
        lastName,
        classID,
        email,
        dob: birthday,
        gender,
        id,
      }));
    }
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{editID ? "Edit" : "Add"} Student</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details of the student:
          </DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2}}>
            <TextField
              autoFocus
              margin="dense"
              id="rollNO"
              label="Roll Number"
              type="number"
              fullWidth
              variant="outlined"
              value={rollNo}
              onChange={(e) => setRollNo(e.target.value)}
              sx={{mr:0.5}}
            />
            <FormControl fullWidth sx={{ml:0.5, mt:0.5}}>
              <InputLabel id="class-select-label">Class</InputLabel>
              <Select
                labelId="class-select-label"
                id="class-select"
                value={classID}
                label="Class"
                onChange={e => setClassID(e.target.value)}
              >
                {
                  allClasses.map((item) => (
                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1}}>
            <TextField
              autoFocus
              margin="dense"
              id="fname"
              label="Student First Name"
              type="text"
              fullWidth
              variant="outlined"
              sx={{mr:0.5}}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="John"
            />
            <TextField
              autoFocus
              margin="dense"
              id="lname"
              label="Student Last Name"
              type="text"
              fullWidth
              variant="outlined"
              sx={{ml:0.5}}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Doe"
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2}}>
            <FormControl fullWidth sx={{mr:1}}>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                value={gender}
                label="Gender"
                onChange={handleGenderChange}
              >
                <MenuItem value={'M'}>Male</MenuItem>
                <MenuItem value={'F'}>Female</MenuItem>
                <MenuItem value={'O'}>Other</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date"
                inputFormat="DD/MM/YYYY"
                value={dob}
                onChange={handleDOBChange}
                renderInput={(params) => <TextField {...params} />}
                sx={{ml:1}}
              />
            </LocalizationProvider>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            sx={{mt: 2}}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="student@skool.cc"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddStudent}>{editID ? "Save" : "Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
