import { createSlice } from '@reduxjs/toolkit';

export const blocklyAssignmentSlice = createSlice({
  name: 'blocklyAssignment',
  initialState: {
    classID: '1',
    selectedAssignment: '1',
    assignments: [
      {
        id: '1',
        name: 'Assignment 1',
        submissions: [
          {id: '1', rollNo: 1, firstName: 'School', lastName: 'Student', submittedOn: '05/12/2022 02:43:22 PM', status: 'Submitted', marks: 5, remarks: 'Good'},
        ],
      },
      {
        id: '2',
        name: 'Assignment 2',
        submissions: [
        ],
      },
    ],
  },
  reducers: {
    setClassID: (state, action) => {
      state.classID = action.payload;
    },
    setSelectedAssignment: (state, action) => {
      state.selectedAssignment = action.payload;
    },
  }
})

export const { setClassID, setSelectedAssignment } = blocklyAssignmentSlice.actions

export default blocklyAssignmentSlice.reducer