import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import QuesitonsList from '../QuestionsList';
import { useSelector, useDispatch } from 'react-redux';
import { setQuizName, setQuizDescription, setQuizCategory, setQuizDifficulty, clearQuiz } from '../../reducers/addQuiz/addQuizSlice';
import { addQuiz } from '../../reducers/quiz/quizSlice';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AddQuiz = (props) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState(0);
  const quizName = useSelector((state) => state.addQuiz.name);
  const quizDescription = useSelector((state) => state.addQuiz.description);
  const quizCategory = useSelector((state) => state.addQuiz.category);
  const quizDifficulty = useSelector((state) => state.addQuiz.difficulty);
  const quizQuestions = useSelector((state) => state.addQuiz.questions);
  const [showSavedMessage, setShowSavedMessage] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeDifficulty = (event) => {
    dispatch(setQuizDifficulty(event.target.value));
  };

  const handleAddQuiz = (e) => {
    e.preventDefault();
    const quiz = {
      id: uuidv4(),
      name: quizName,
      description: quizDescription,
      category: quizCategory,
      difficulty: quizDifficulty,
      questions: quizQuestions,
    };
    dispatch(addQuiz(quiz));
    setShowSavedMessage(true);
    setTimeout(() => {
      setShowSavedMessage(false);
      dispatch(clearQuiz());
    }, 5000);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={showSavedMessage}>
        <Alert sx={{mb:2}}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowSavedMessage(false);
              }}
            >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>Quiz saved successfully!</Alert>
      </Collapse>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <Typography variant="h6">Add Quiz</Typography>
        </Box>
        <Button variant="contained" color="primary" onClick={handleAddQuiz} startIcon={<SaveOutlinedIcon />}>
          Save
        </Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Questions" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <FormControl fullWidth sx={{my:1}}>
          <TextField id="quiz-name" label="Name" variant="outlined" value={quizName} onChange={e => dispatch(setQuizName(e.target.value))} />
        </FormControl>
        <FormControl fullWidth sx={{my:1}}>
          <TextField id="quiz-description" label="Description" variant="outlined" value={quizDescription} onChange={e => dispatch(setQuizDescription(e.target.value))} />
        </FormControl>
        <FormControl fullWidth sx={{my:1}}>
          <TextField id="quiz-category" label="Category" variant="outlined" value={quizCategory} onChange={e => dispatch(setQuizCategory(e.target.value))} />
        </FormControl>
        <FormControl fullWidth sx={{my:1}}>
          <InputLabel id="difficulty-select-label">Difficulty</InputLabel>
          <Select
            labelId="difficulty-select-label"
            id="difficulty-select"
            value={quizDifficulty}
            label="Difficulty"
            onChange={handleChangeDifficulty}
          >
            <MenuItem value={'easy'}>Easy</MenuItem>
            <MenuItem value={'moderate'}>Moderate</MenuItem>
            <MenuItem value={'hard'}>Hard</MenuItem>
          </Select>
        </FormControl>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <QuesitonsList />
      </TabPanel>
    </Box>
  );
};

export default AddQuiz;
