import { createSlice } from '@reduxjs/toolkit';

function createData(rollNo, firstName, lastName, classID, email, dob, gender, id) {
  return {
    rollNo,
    firstName,
    lastName,
    classID,
    email,
    dob,
    gender,
    id,
  };
}

const rows = [
  createData(1, 'Aarav', 'Mittal', '3', 'aarav@skool.cc', '2015-07-29', 'M', '1'),
  createData(2, 'Rohit', 'Gupta', '4', 'rohit@skool.cc', '2014-02-13', 'M', '2'),
  createData(3, 'Shyama', 'Mukherje', '2', 'shyama@skool.cc', '2016-05-30', 'M', '3'),
  createData(4, 'Ritesh', 'Mathur', '1', 'ritesh@skool.cc', '2017-04-11', 'M', '4'),
  createData(5, 'Vaihav', 'Shah', '3', 'vaihav@skool.cc', '2015-07-29', 'M', '5'),
  createData(6, 'Malini', 'Ayer', '1', 'malini@skool.cc', '2015-07-29', 'F', '6'),
  createData(7, 'Shalu', 'Kumari', '2', 'shalu@skool.cc', '2015-07-29', 'F', '7'),
  createData(8, 'Shubam', 'Kumar', '3', 'shubam@skool.cc', '2015-07-29', 'M', '8'),
  createData(9, 'Asif', 'Mohammad', '1', 'asif@skool.cc', '2015-07-29', 'M', '9'),
  createData(10, 'Ritika', 'Munoth', '2', 'ritika@skool.cc', '2015-07-29', 'F', '10'),
  createData(11, 'Payal', 'Sareen', '1', 'payal@skool.cc', '2015-07-29', 'F', '11'),
  createData(12, 'Anshul', 'Gupta', '3', 'anshul@skool.cc', '2015-07-29', 'M', '12'),
  createData(13, 'Venkat', 'Babu', '2', 'venkat@skool.cc', '2015-07-29', 'M', '13'),
];

export const studentsSlice = createSlice({
  name: 'students',
  initialState: {
    students: rows,
  },
  reducers: {
    addStudent: (state, action) => {
      state.students = [...state.classes, action.payload];
    },
    removeStudent: (state, action) => {
      state.students = state.students.filter((item) => item.id !== action.payload);
    },
    editStudent: (state, action) => {
      state.students = state.students.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
  }
})

export const { addStudent, removeStudent, editStudent } = studentsSlice.actions

export default studentsSlice.reducer