import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const CodeAssignment = (props) => {
  return (
    <Box>Soon...</Box>
  )
}

CodeAssignment.propTypes = {}

export default CodeAssignment;
