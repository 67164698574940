import { createSlice } from '@reduxjs/toolkit';

export const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    quizzes: [],
  },
  reducers: {
    addQuiz: (state, action) => {
      state.quizzes = [...state.quizzes, action.payload];
    },
    deleteQuiz: (state, action) => {
      state.quizzes = state.quizzes.filter(quiz => quiz.id !== action.payload);
    },
  }
})

export const { addQuiz, deleteQuiz } = quizSlice.actions

export default quizSlice.reducer