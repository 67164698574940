import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataGrid } from '@mui/x-data-grid';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { setClassID, setSelectedAssignment } from '../../reducers/blocklyAssignment/blocklyAssignmentSlice';

const columns = [
  {
    field: 'rollNo',
    headerName: 'Roll No',
    type: 'number',
    width: 80,
    editable: false,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 150,
    editable: false,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 150,
    editable: false,
  },
  {
    field: 'submittedOn',
    headerName: 'Submitted On',
    width: 180,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 135,
    editable: true,
    type: 'singleSelect',
    valueOptions: [
      { value: 'Submitted', label: 'Submitted' },
      { value: 'Accepted', label: 'Accepted' },
      { value: 'Rejected', label: 'Rejected' }
    ],
  },
  {
    field: 'marks',
    headerName: 'Marks',
    type: 'number',
    width: 70,
    editable: true,
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    width: 250,
    editable: true,
  }
];

const ViewBlocklyAssignment = (props) => {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classes.classes);
  const assignments = useSelector((state) => state.blocklyAssignment.assignments);
  const classID = useSelector((state) => state.blocklyAssignment.classID);
  const selectedAssignment = useSelector((state) => state.blocklyAssignment.selectedAssignment);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleClassChange = (event) => {
    dispatch(setClassID(event.target.value));
  };

  const handleAssignmentChange = (event) => {
    dispatch(setSelectedAssignment(event.target.value));
  };

  const handleOnSendRemarks = (e) => {
    e.preventDefault();
  };

  const handleOnOpenAssignment = (e) => {
    e.preventDefault();
  };
  
  return (
    <Box sx={{width: '-webkit-fill-available'}}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={classID}
              label="Class"
              onChange={handleClassChange}
            >
              {
                classes.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="course-select-label">Assignment</InputLabel>
            <Select
              labelId="course-select-label"
              id="course-select"
              value={selectedAssignment}
              label="Assignment"
              onChange={handleAssignmentChange}
            >
              {
                assignments.map((item) => (
                  <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
        </Box>
        <Button variant="contained" color="primary" onClick={e => {}} startIcon={<SyncOutlinedIcon />}>
          Load
        </Button>
      </Box>
      <Box sx={{display:"flex", justifyContent:"flex-end", alignItems:"center", mt: 5, mb:2, width: '-webkit-fill-available'}}>
        <Button startIcon={<SendOutlinedIcon />} onClick={handleOnSendRemarks}>Send Remarks</Button>
        <Button startIcon={<OpenInNewOutlinedIcon />} onClick={handleOnOpenAssignment}>Open Project</Button>
      </Box>
      <Box sx={{ height: 371, width: '100%' }}>
        <DataGrid
          rows={assignments.find(item => item.id === selectedAssignment)?.submissions || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
          selectionModel={selectedRows}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
  );
};

ViewBlocklyAssignment.propTypes = {};

export default ViewBlocklyAssignment;
