import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';

export default function JoinClassLink({open, handleClose, generateClassLink}) {
  const allClasses = useSelector((state) => state.classes.classes);
  const [classID, setClassID] = React.useState(allClasses.length > 0 ? allClasses[0].id : '');

  const handleGenerateJoinClassLink = () => {
    generateClassLink(classID);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Join Class Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the class to generate the link:
          </DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2}}>
            <FormControl fullWidth sx={{ml:0.5, mt:0.5}}>
              <InputLabel id="class-select-label">Class</InputLabel>
              <Select
                labelId="class-select-label"
                id="class-select"
                value={classID}
                label="Class"
                onChange={e => setClassID(e.target.value)}
              >
                {
                  allClasses.map((item) => (
                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleGenerateJoinClassLink}>Generate</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
