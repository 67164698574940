import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const CustomGameLevel = (props) => {
  return (
    <Box>Soon...</Box>
  )
}

CustomGameLevel.propTypes = {}

export default CustomGameLevel;
