import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {db, auth} from '../../firebase/config';
import {doc, getDoc} from 'firebase/firestore';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {setUser} from '../../reducers/user/userSlice';

export default function LoginDialog({open, onClose, editID}) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginStatus, setShowLoginStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Invalid email or password.");

  const handleUserLogin = (e) => {
    e.preventDefault();
    if(email.length === 0) {
      setErrorMessage("Email is required.");
      setShowLoginStatus(true);
      return;
    } else if(password.length === 0) {
      setErrorMessage("Password is required.");
      setShowLoginStatus(true);
      return;
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(res => {
          dispatch(setUser(res.user.uid));
          onClose();
        })
        .catch(() => {
          setErrorMessage("Invalid email or password.");
          setShowLoginStatus(true);
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    onClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Collapse in={showLoginStatus}>
              <Alert severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowLoginStatus(false);
                    }}
                  >
                  <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {errorMessage}
              </Alert>
            </Collapse>
            Enter the user details to login:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            placeholder='radhakrishnan@skool.cc'
            sx={{mt:2}}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            placeholder='secret'
            sx={{mt:2}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserLogin} sx={{mr:1}}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
