import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { changeAttendanceMonth, changeAttendanceYear, changeAttendanceClass } from '../../reducers/attendance/attendanceSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 2,
    maxWidth: '1px',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 2,
    textAlign: 'center',
  },
}));

const StyledLeftAlignTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 2,
    maxWidth: '1px',
    paddingLeft: 10,
    textAlign: 'left',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 2,
    paddingLeft: 10,
    textAlign: 'left',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export default function Attendance() {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classes.classes);
  const students = useSelector((state) => state.students.students);
  const month = useSelector((state) => state.attendance.month);
  const year = useSelector((state) => state.attendance.year);
  const classID = useSelector((state) => state.attendance.classID);
  const attendance = useSelector((state) => state.attendance.attendance);

  const handleClassChange = (event) => {
    dispatch(changeAttendanceClass(event.target.value));
  };

  const handleMonthChange = (event) => {
    dispatch(changeAttendanceMonth(event.target.value));
  };

  const handleYearChange = (event) => {
    dispatch(changeAttendanceYear(event.target.value));
  };

  const exportToCSV = (e) => {
    e.preventDefault();
    const csvData = [];
    const days = getDaysInMonth(month, year);
    const header = ['Roll No', 'Name'];
    Array.from(Array(days).keys()).forEach((day) => {
      header.push(day + 1);
    });
    csvData.push(header);
    attendance.forEach((student) => {
      csvData.push([students[student.id].rollNo, `${students[student.id].firstName} ${students[student.id].lastName}`, ...student.attendance]);
    });
    const csvString = csvData.map(e => e.join(", ")).join("\n");
    const downloadLink = document.createElement("a");
    const blob = new Blob(["\ufeff", csvString]);
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `attendance-${month}-${year}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={classID}
              label="Class"
              onChange={handleClassChange}
            >
              {
                classes.map((schoolClass) => (
                  <MenuItem key={schoolClass.id} value={schoolClass.id}>{schoolClass.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <FormControl sx={{width: "10rem"}}>
            <InputLabel id="month-select-label">Month</InputLabel>
            <Select
              labelId="month-select-label"
              id="month-select"
              value={month}
              label="Month"
              onChange={handleMonthChange}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <FormControl sx={{width: "10rem"}}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              label="Year"
              onChange={handleYearChange}
            >
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ml: 2, flexGrow: "1"}}></Box>
        </Box>
        <Button variant="contained" color="primary" startIcon={<FileDownloadOutlinedIcon />} onClick={exportToCSV}>
          Download
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size={'medium'}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              {
                Array.from(Array(getDaysInMonth(month, year)).keys()).map((day) => (
                  <StyledTableCell align="right" key={day+1}>{day+1}</StyledTableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              attendance.map((attendance, day) => {
                return (
                  <StyledTableRow key={attendance.id}>
                    <StyledLeftAlignTableCell component="th" scope="row">
                      {students[attendance.id].firstName} {students[attendance.id].lastName}
                    </StyledLeftAlignTableCell>
                    {
                      attendance.attendance.map((present, day) => (
                        <StyledTableCell align="right" key={day}>
                          <Typography color={green[700]}>
                            {present}
                          </Typography>
                        </StyledTableCell>
                      ))
                    }
                  </StyledTableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
