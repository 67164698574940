import { createSlice } from '@reduxjs/toolkit';

export const addQuizSlice = createSlice({
  name: 'addQuiz',
  initialState: {
    name: '',
    description: '',
    category: '',
    difficulty: 'easy',
    questions: [
      { id: '1', question: 'Which is the largest country in the world?', optionA: 'Brazil', optionB: 'China', optionC: 'Russia', optionD: 'United States of America', answer: 'C', timeLimit: '1:00', marks: 1 },
      { id: '2', question: 'What is the name of the largest river in the world?', optionA: 'Amazon', optionB: 'Mississippi', optionC: 'Nile', optionD: 'Yangtze', answer: 'C', timeLimit: '1:00', marks: 1 },
      { id: '3', question: 'Name the largest desert in the world?', optionA: 'Antarctic Desert', optionB: 'Arabian Desert', optionC: 'Australian Desert', optionD: 'Sahara Desert', answer: 'A', timeLimit: '1:00', marks: 1 },
      { id: '4', question: 'What is the name of the largest ocean in the world?', optionA: 'Atlantic Ocean', optionB: 'Indian Ocean', optionC: 'Pacific Ocean', optionD: 'Southern Ocean', answer: 'C', timeLimit: '1:00', marks: 1 },
      { id: '5', question: 'What is the name of the largest mountain in the world?', optionA: 'Mount Everest', optionB: 'K2', optionC: 'Kangchenjunga', optionD: 'Lhotse', answer: 'A', timeLimit: '1:00', marks: 1 },
      { id: '6', question: 'What is the name of the largest lake in the world?', optionA: 'Lake Baikal', optionB: 'Lake Superior', optionC: 'Lake Victoria', optionD: 'Lake Tanganyika', answer: 'B', timeLimit: '1:00', marks: 1 },
      { id: '7', question: 'What is the name of the largest island in the world?', optionA: 'Greenland', optionB: 'New Guinea', optionC: 'Madagascar', optionD: 'Borneo', answer: 'A', timeLimit: '1:00', marks: 1 },
    ]
  },
  reducers: {
    setQuizName: (state, action) => {
      state.name = action.payload;
    },
    setQuizDescription: (state, action) => {
      state.description = action.payload;
    },
    setQuizCategory: (state, action) => {
      state.category = action.payload;
    },
    setQuizDifficulty: (state, action) => {
      state.difficulty = action.payload;
    },
    addQuizQuestion: (state, action) => {
      state.questions = [...state.questions, action.payload];
    },
    editQuizQuestion: (state, action) => {
      const index = state.questions.findIndex((question) => question.id === action.payload.id);
      state.questions[index] = action.payload;
      state.questions = [...state.questions];
    },
    deleteQuizQuestions: (state, action) => {
      state.questions = state.questions.filter(question => !action.payload.includes(question.id));
    },
    clearQuiz: (state) => {
      state.name = '';
      state.description = '';
      state.category = '';
      state.difficulty = 'easy';
      state.questions = [];
    },
  }
})

export const { setQuizName, setQuizDescription, setQuizCategory, setQuizDifficulty, addQuizQuestion, deleteQuizQuestions, clearQuiz, editQuizQuestion } = addQuizSlice.actions

export default addQuizSlice.reducer