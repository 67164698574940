import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { selectClasses } from '@mui/material/Select';
import { Typography } from '@mui/material';
import { green, red, amber, grey } from '@mui/material/colors';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector, useDispatch } from 'react-redux';

export default function TakeAttendance() {
  const classes = useSelector((state) => state.classes.classes);
  const students = useSelector((state) => state.students.students);
  const [selectClass, setSelectClass] = React.useState(classes.length > 0 ? classes[0].id : '');
  const [allAttendance, setAllAttendance] = React.useState('P');
  const [studentsAttendance, setStudentsAttendance] = React.useState([]);

  const handleAllAttendanceChange = (event) => {
    event.preventDefault();
    setAllAttendance(event.target.value);
    setStudentsAttendance(studentsAttendance.map((item) => {
      return {
        ...item,
        attendance: event.target.value
      }
    }));
  };

  const handleClassChange = (event) => {
    setSelectClass(event.target.value);
  };

  const [attendanceDay, setAttendanceDay] = React.useState(dayjs('2022-11-29'));

  const handleAttendanceDayChange = (newValue) => {
    setAttendanceDay(newValue);
  };

  React.useEffect(() => {
    if (selectClass) {
      const classStudents = students.filter((item) => item.classID === selectClass);
      setStudentsAttendance(classStudents.map((item) => ({ id: item.id, attendance: 'P' })));
    }
  }, [selectClass, students]);

  const handleStudentAttendanceChange = (event, id) => {
    event.preventDefault();
    const newStudentsAttendance = studentsAttendance.map((studentAttendance) => {
      if (studentAttendance.id === id) {
        return { ...studentAttendance, attendance: event.target.value };
      }
      return studentAttendance;
    });
    setStudentsAttendance(newStudentsAttendance);
  };

  const getStudentName = (id) => {
    const student = students.find((item) => item.id === id);
    return student ? `${student.firstName} ${student.lastName}`: '';
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", mb: 2}}>
        <Box sx={{display:"flex", flexDirection: "row", alignItems: "center", flexGrow: "1"}}>
          <FormControl sx={{width: "20rem"}}>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={selectClass}
              label="Class"
              onChange={handleClassChange}
            >
              {
                classes.map((item, index) => {
                  return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <Box sx={{mx: 1}}></Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date"
              inputFormat="DD/MM/YYYY"
              value={attendanceDay}
              onChange={handleAttendanceDayChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Box sx={{mx: 1}}></Box>
        </Box>
        <Button variant="contained" color="primary" onClick={e => {}} startIcon={<SaveOutlinedIcon />}>
          Save
        </Button>
      </Box>
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        subheader={<ListSubheader>Take Attendance</ListSubheader>}
      >
        <ListItem>
          <ListItemIcon>
            <AccountBoxOutlinedIcon />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Mark Everyone" />
          <FormControl sx={{width: '-webkit-fill-available', maxWidth: '5rem'}}>
            <Select
              id="demo-simple-select"
              value={allAttendance}
              onChange={handleAllAttendanceChange}
            >
              <MenuItem value={'P'}><Typography variant="subtitle1" color={green[500]} sx={{fontWeight: 700}}>P</Typography></MenuItem>
              <MenuItem value={'A'}><Typography variant="subtitle1" color={red[500]} sx={{fontWeight: 700}}>A</Typography></MenuItem>
              <MenuItem value={'L'}><Typography variant="subtitle1" color={amber[500]} sx={{fontWeight: 700}}>L</Typography></MenuItem>
              <MenuItem value={'H'}><Typography variant="subtitle1" color={grey[500]} sx={{fontWeight: 700}}>H</Typography></MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <Divider />
        {
          studentsAttendance.map(({id, attendance}, index) => {
            return (
              <ListItem key={id}>
                <ListItemIcon>
                  <AccountBoxOutlinedIcon />
                </ListItemIcon>
                <ListItemText id="student-attendance-label" primary={getStudentName(id)} />
                <FormControl sx={{width: '-webkit-fill-available', maxWidth: '5rem'}}>
                  <Select
                    id="student-attendance-select"
                    value={attendance}
                    onChange={e => handleStudentAttendanceChange(e, id)}
                  >
                    <MenuItem value={'P'}><Typography variant="subtitle1" color={green[500]} sx={{fontWeight: 700}}>P</Typography></MenuItem>
                    <MenuItem value={'A'}><Typography variant="subtitle1" color={red[500]} sx={{fontWeight: 700}}>A</Typography></MenuItem>
                    <MenuItem value={'L'}><Typography variant="subtitle1" color={amber[500]} sx={{fontWeight: 700}}>L</Typography></MenuItem>
                    <MenuItem value={'H'}><Typography variant="subtitle1" color={grey[500]} sx={{fontWeight: 700}}>H</Typography></MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            )
          })
        }
      </List>
    </Box>
  );
}