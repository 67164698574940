import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

export default function ViewSolution({open, onClose}) {
  const [firstName, setFirstName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [grade, setGrade] = React.useState('');
  const [section, setSection] = React.useState('');
  const [gender, setGender] = React.useState('M');

  const handleChange = (event) => {
    setGender(event.target.value);
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Solution</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>
          <AceEditor
            mode="javascript"
            theme="github"
            onChange={e => {}}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            fontSize={16}
            value={`start();\nwalkForward(2);\nturn(90);\nwalkForward(1);\ncollectTomato();`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Reject</Button>
          <Button onClick={onClose}>Accept</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
