import { createSlice } from '@reduxjs/toolkit';

const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState: {
    month: 1,
    year: 2022,
    classID: '1',
    attendance: [
      {
        id: '1',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '2',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '3',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '4',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '5',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '6',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '7',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '8',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '9',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
      {
        id: '10',
        attendance: ['P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P', 'P'],
      },
    ],
  },
  reducers: {
    changeAttendanceMonth: (state, action) => {
      state.month = action.payload;
      state.attendance = state.attendance.map((student) => {
        student.attendance = Array(getDaysInMonth(state.month, state.year)).fill('P');
        return student;
      });
    },
    changeAttendanceYear: (state, action) => {
      state.year = action.payload;
      state.attendance = state.attendance.map((student) => {
        student.attendance = Array(getDaysInMonth(state.month, state.year)).fill('P');
        return student;
      });
    },
    changeAttendanceClass: (state, action) => {
      state.classID = action.payload;
    },
  }
})

export const { changeAttendanceMonth, changeAttendanceYear, changeAttendanceClass } = attendanceSlice.actions

export default attendanceSlice.reducer